// aws-exports.js

const region = process.env.REACT_APP_AWS_REGION;
const region_s3_bucket = process.env.REACT_APP_AWS_REGION_S3_BUCKET;
const s3_bucket_name = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
const user_pool_id = process.env.REACT_APP_AWS_USER_POOL_ID;
const user_pool_app_client = process.env.REACT_APP_AWS_USER_POOL_APP_CLIENT_ID;
const access_key_id = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secret_access_key = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const AwsExports = 
    {
        "REGION": region,
        "REGION_S3_BUCKET": region_s3_bucket,
        "S3_BUCKET_NAME": s3_bucket_name,
        "USER_POOL_ID": user_pool_id,
        "USER_POOL_APP_CLIENT_ID": user_pool_app_client,
        "ACCESS_KEY_ID": access_key_id,
        "SECRET_ACCESS_KEY": secret_access_key
    }

export default AwsExports;