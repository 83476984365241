import React, { Suspense, lazy } from 'react';

// aws
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { I18n } from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';

// material-ui
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const AdminApp = lazy(() => import('./AdminApp'));

I18n.setLanguage('it');
I18n.putVocabularies(translations);
I18n.putVocabularies({
  it: {
    'Enter your Password': "Password",
    'Please confirm your Password': "Password",
    'Enter your Email': "Email",
    'User does not exist.': "Utente non registrato",
    "Your passwords must match": "Le password non coincidono",
    "Incorrect username or password.": "Credenziali errate",
    "Reset Password": "Recupera password", 
    "Enter your Username": "Nome utente", 
    "Enter your username": "Nome utente", 
  },
});

function App() {
  return (
    <div>
      <Suspense fallback={
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>    
      }>
        <Authenticator variation='modal' hideSignUp={true}>
          {({ signOut, user }) => (
            <AdminApp signOut={signOut} username={user}/>
          )}
      </Authenticator>
    </Suspense>
    </div>
  );
}

export default App;